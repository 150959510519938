<template>
	<div>
		<s-crud
			title="Cámaras de Enfriamiento"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove
		>
			<template scope="props">
				<v-container>
					<v-row justify="center">
						<v-col cols="12" lg="3" md="3" class="s-col-form">
							<s-text v-model="props.item.CchName" label="Descripción" />
						</v-col>
						<v-col cols="12" lg="3" md="3"  class="s-col-form">
							<s-text
							v-model="props.item.CchCapacity"
							label="Capacidad (Nº Palets)"
							/>
						</v-col>
						<v-col cols="12" lg="3" md="3"  class="s-col-form">
							<s-select
								:items="itemsStateTunnel"
								item-value="MtsID"
								item-text="MtsDescription"
								label="Estado"
								v-model="props.item.MtsID"
							>
							</s-select>
							<!-- <s-select-definition
								v-model="props.item.FtmStatus"
								:def="1321"
								label="Estado"
							>
							</s-select-definition> -->
						</v-col>

						<!-- <v-col cols="12" class="s-col-form" sm="4" md="8" lg="4">
							<s-select-definition
							v-model="props.item.CchStatus"
							:def="1321"
							label="Estado"
							>
							</s-select-definition>
						</v-col> -->
					</v-row>
				</v-container>
			</template>
			<template v-slot:CchStatus="{ row }">
					<v-chip
						style="margin: 0px"
						x-small
						:color="row.CchStatus == 1 ? 'success' : 'error'"
					>
						{{ row.CchStatus == 1 ? "Activo" : "Inactivo" }}
					</v-chip>
				</template>
		</s-crud>
	</div>
</template>

<script>
	import _service from "@/services/FrozenProduction/CoolingChamberService";
	import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';

	export default {
		data() {
			return {
				filter: {},
				config: {	
					service: _service,
					model: {
						CchID: "ID",
						CchStatus: "",
					},
					headers: [
						{
						//
						text: "ID",
						value: "CchID"
						},
						{
							text: "Descripción",
							value: "CchName",
							width: "55%",
						},
						{
							text: "Estado",
							value: "MtsDescription",
							width: "45%",
							sorteable: true,
						},
						{
						text: "Capacidad (Nº Pallet)",
						value: "CchCapacity",
						width: "25%",
						},
						{
						text: "Estado",
						value: "CchStatus",
						},
					],
				},
			}
		},

		created() 
		{
			this.initialize()
		},

		methods: {

			initialize(){
				_sMaturationTunnelPalletService.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data
					}
				})
			},

			save(item) {
				if (item.CchName.length == 0) {
					this.$fun.alert("Registre descripción de valor", "warning");
					return;
				}
				if(item.CchCapacity == 0 || item.CchCapacity == ""){
					this.$fun.alert("Ingrese capacidad", "warning");
					return;
				}

				item.CchStatus = 1
				item.SecStatus = 1
				item.UsrCreateID = this.$fun.getUserID()
				item.UsrUpdateID = this.$fun.getUserID()

				item.save();
			}
		},
	}
</script>